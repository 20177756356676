<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <sidebar-group title="Search|Recherche">
            <b-form-group>
                <btn-responsive-prop v-model="block" field="autofocus" for-checkbox/>
                <b-checkbox v-model="block.mediaProxy.autofocus">
                    <span v-tr>Focus automatically|Focus automatiquement</span>
                </b-checkbox>
            </b-form-group>

            <e-translation-input label="Placeholder|Indication" v-model="block.placeholder"/>

            <b-form-group :label="''|tr">
                <template #label>
                    <span v-tr>Input Shape|Forme de la case de recherche</span>
                    <btn-responsive-prop v-model="block" field="borderRadius"/>
                </template>
                <input-border-radius v-model="block.mediaProxy.borderRadius"/>
            </b-form-group>

            <b-form-group>
                <btn-responsive-prop v-model="block" field="fillWidth" for-checkbox/>
                <b-checkbox v-model="block.mediaProxy.fillWidth">
                    <span v-tr>Fill Width|Remplir la largeur</span>
                </b-checkbox>
            </b-form-group>

            <b-form-group label-cols="4" :label="'Size|Taille'|tr">
                <template #label>
                    <span v-tr>Size|Taille</span>
                    <btn-responsive-prop v-model="block" field="size"/>
                </template>
                <b-select v-model="block.mediaProxy.size">
                    <option value="xs" v-tr>Extra Small|Très petit</option>
                    <option value="sm" v-tr>Small|Petit</option>
                    <option value="md" v-tr>Normal</option>
                    <option value="lg" v-tr>Large</option>
                    <option value="xl" v-tr>Extra Large|Très large</option>
                    <option value="xxl" v-tr>Ultra Large|Ultra large</option>
                </b-select>
            </b-form-group>
        </sidebar-group>

        <sidebar-group title="Search Results|Résultats de recherche">
            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Columns|Colonnes</span>
                    <btn-responsive-prop v-model="block" field="columns"/>
                </template>
                <b-select v-model.number="block.mediaProxy.columns">
                    <option v-for="n in 5" :key="`columns-${n}`" :value="`${n}`">{{n}}</option>
                </b-select>
            </b-form-group>
            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Spacing|Espacement</span>
                    <btn-responsive-prop v-model="block" field="spacing"/>
                </template>
                <b-select v-model="block.mediaProxy.spacing">
                    <option value="0" v-tr>None|Sans</option>
                    <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Max Results|Max résultats</span>
                    <btn-responsive-prop v-model="block" field="maxSearchResult"/>
                </template>
                <b-select v-model.number="block.mediaProxy.maxSearchResult">
                    <option v-for="n in 30" :key="`results-${n}`" :value="n">{{n}}</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Max Height|Hauteur max</span>
                    <btn-responsive-prop v-model="block" field="maxHeight"/>
                </template>
                <b-select v-model="block.mediaProxy.maxHeight">
                    <option value="none" v-tr>Auto</option>
                    <option v-for="n in 50" :key="`height-${n}`" :value="`${(n + 1) * 25}px`">{{(n + 1) * 25}}px</option>
                </b-select>
            </b-form-group>

            <b-form-group>
                <b-checkbox v-model="block.displayOnSearchOnly">
                    <span v-tr>Display only when searching|Afficher seulement lors de la recherche</span>
                </b-checkbox>
            </b-form-group>
        </sidebar-group>

        <properties-component-text title="Title|Titre" :value="block.title" v-bind="componentProps"/>
        <properties-component-text title="Subtitle|Sous-titre" :value="block.subtitle" v-bind="componentProps"/>
        <properties-component-border title="Divider|Séparateur" :value="block.divider" v-bind="componentProps"/>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import PropertiesComponentImage from "@/components/editor/properties/components/properties-component-image.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import ETranslationInput from "../../../../../vue-components/components/e-translation-input.vue";

// properties-block-search #hot-reload-debug
export default {
    name: `properties-block-search`,
    components: {PropertiesComponentImage, PropertiesComponentText, SidebarGroup, ETranslationInput, InputBorderRadius, PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
